@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), url('./assets/fonts/OpenSansHebrew-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), url('./assets/fonts/OpenSansHebrew-Bold.ttf') format('truetype');
    font-weight: bold;
}

body {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
}