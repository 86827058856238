.vgs h1,.vgs h2,.vgs h3,.vgs b {
    font-weight: bold;
}

.vgs h1 {
    font-size: 110%;
    margin: 1.5rem 0;
}

.vgs h2 {
    font-size: 105%;
    margin: 1.5rem 0;
}

.vgs h3 {
    font-size: 102.5%;
    margin: 1.5rem 0;
}

.vgs ul {
    list-style: disc;
    list-style-position: outside;
    margin-right: 1rem;
}